import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Budget from './components/Budget';
import Remaining from './components/Remaining';
import ExpenseTotal from './components/Expensestotal';
import ExpenseList from './components/ExpenseList';
import AddExpenseForm from './components/AddExpense'
import {AppProvider} from './Context/AppContext'

const App = () => {
	return (
		<AppProvider>
			<div className='container'>
				<h1 className='mt-3'>My Budget Planner</h1>
				<div className='row mt-3'>
					<div className='col-sm'>
						<Budget />
					</div>
					<div className='col-sm'>
						<Remaining />
					</div>
					<div className='col-sm'>
						<ExpenseTotal />
					</div>
				</div>
				<h3 className='mt-3'>Expense List</h3>
				<div className='col-sm'>
				<ExpenseList/>
				</div>
				<h3 className='mt-3'>Add Expense</h3>
				<div className='col-sm'>
					<AddExpenseForm/>
				</div>
			</div>
		</AppProvider>
	);
};

export default App;